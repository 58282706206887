$fade-animation-duration: 150ms;

.image {
  position: relative;
  overflow: hidden;

  &.withZoom {
    overflow: visible;

    img {
      transition: transform 300ms;
    }
  }
}

.fill {
  width: 100%;
  height: 100%;
}

.placeholderContainer {
  position: absolute;

  left: 0;
  right: 0;

  .blurPlaceholder {
    filter: blur(12px);
  }
}

.fadeIn {
  transition-property: opacity, visibility;
  transition-duration: $fade-animation-duration;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit;
  }
}

.skeletonLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  // Delay the hiding of this element to prevent flashing before
  // the fade in transition is complete.
  transition-delay: $fade-animation-duration;
  transition-property: display;

  &.hidden {
    display: none;
  }
}
