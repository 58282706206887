@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// Sections container
$sections-width: 95% !default;
$sections-width-desktop: 95% !default;
$sections-margin: $space-xxs auto !default;
$sections-margin-desktop: $space-sm auto !default;

// Section label styles
$section-font-weight: $font-weight-base !default;
$section-font-size: $font-size-sm !default;
$section-font-size-desktop: $font-size-lg !default;
$section-text-transform: uppercase !default;
$section-margin: $space-xxs 0 !default;
$section-margin-desktop: 0 auto $space-xs !default;
$section-border-color: $color-gray !default;

// Article content (within accordion) styles
$article-font-weight: $font-weight-base !default;
$article-font-size: $font-size-base !default;
$article-padding: 1px 0 !default; // Avoid jank when animating

// Sets scrolling behavior to account for the nav bar height.
// TODO: set this property on scrollable element instead of globally.
:has(.container) {
  scroll-behavior: smooth;
  scroll-padding-top: $header-height;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: $space-lg;
  scroll-behavior: smooth;
}

.pageColumns {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  border-bottom: 1px solid $color-border;
  margin-bottom: $space-lg;
}

.carouselContainer {
  width: 100%;

  @include md-min {
    display: block;
    width: calc(50% - ($space-md * 2));
    margin: 0 $space-md;
  }

  @include lg-min {
    width: calc(60% - ($space-md * 2));
  }

  @include xl-min {
    width: calc(75% - ($space-md * 2));
  }
}

.productImage {
  width: 100%;
  margin-top: $space-xs;
  margin-bottom: $space-xs;
}

.variationContainer {
  width: 100%;

  @include md-min {
    width: calc(40% - $space-xs);
    margin-left: $space-xs;
    border-left: 1px solid $color-border;
  }

  @include lg-min {
    padding-top: $space-xl;
    width: calc(35% - $space-xs);
  }

  @include xl-min {
    width: calc(25% - $space-xs);
  }
}

.sections {
  width: $sections-width;
  margin: $sections-margin;
  padding: 0;
  max-width: $wrapper-max-width;

  @include xl-min {
    width: $sections-width-desktop;
    margin: $sections-margin-desktop;
    padding: 0 $space-xl;
  }
}

.section,
.reviewsSection {
  width: 100%;
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  font-weight: $section-font-weight;
  font-size: $section-font-size;
  text-transform: $section-text-transform;
  text-align: left;
  margin: $section-margin;

  @include md-min {
    margin: $section-margin-desktop;
  }

  @include lg-min {
    font-size: $section-font-size-desktop;
  }

  &::after {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $section-border-color;
  }
}

.accordion {
  overflow: hidden;

  article {
    font-weight: $article-font-weight;
    font-size: $article-font-size;
    padding: $article-padding;
  }
}

.contentContainer {
  margin: $space-md 0;
}

.breadcrumbs {
  display: none;
  
  @include md-min {
    display: block;
    margin: 0 0 $space-md;
  }
}

.shortDescription {
  font-weight: $article-font-weight;
  font-size: $article-font-size;
  padding-inline: $space-lg;
  border-bottom: 1px solid $color-border;
}

.content {
  // Match the max right edge of the reviews section
  @include xl-min {
    padding: 0 calc(2.5vw + $space-xl) 0 0;
    max-width: $max-product-info-width;
  }
}

.locallyWrapper {
  margin-top: $space-md;
}
