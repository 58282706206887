@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $sections-width: auto,
  $sections-margin: $space-xxs $space-lg,
  $section-font-weight: $font-weight-medium,
  $section-font-size: $font-size-xs,
  $section-font-size-desktop: $font-size-sm,
  $section-text-transform: none,
  $section-margin: $space-sm 0 calc($space-sm - 2px),
  $section-margin-desktop: $space-sm 0 calc($space-sm - 2px),
  $section-border-color: $color-border,
  $article-font-weight: $font-weight-xthin,
  $article-font-size: $font-size-md,
  $article-padding: 1px 0 $space-xxs,
);

// For AHNU, display the divider at the bottom of the container,
// not at the bottom of the section (ie. label element).
.section:not(.reviewsSection) {
  &::after {
    display: none; // Hide the divider
  }
}

.accordion {
  --rotation: 180deg; // Used by icon animations

  article {
    p:first-child {
      margin-top: 0;
    }
  }
}
