@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.container {
  height: auto;
}

:is(body) {
  .thumbnails {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .navigationWrapper {
    background-color: $color-secondary-100;
    border-bottom: 1px solid $color-border;

    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  .navigation {
    // This property is causing issues in Safari
    // when nested in a container that also uses aspect-ratio (i.e. it squishes elements).
    aspect-ratio: unset;
    width: 25px;
    height: 25px;
    border: none;
    background: $carousel-navigation-background;

    &:hover {
      background: $carousel-navigation;
      color: $carousel-navigation-background;
    }

    svg {
      transform: scale(0.7);
    }
  }

  .circle {
    width: 8px;
    height: 8px;
    outline: 1px solid $color-secondary-900;
    outline-offset: 0;
    background-color: transparent;
    margin: $space-xxxs;
  }

  .activeCircle {
    background-color: $color-secondary-900;
  }
}
