////
/// Defines variables relating to default transitions and animations.
/// @group variables/transitions
////

// Transition durations (in milliseconds) selections are 120ms, 150ms, 200ms, 250ms, 300ms, 500ms, 1000ms
$transition-duration-fast-4: 120ms !default;
$transition-duration-fast-3: 150ms !default;
$transition-duration-fast-2: 200ms !default;
$transition-duration-fast-1: 250ms !default;
$transition-duration: 300ms !default; // This is the default as it is most commonly used.
$transition-duration-slow-1: 500ms !default;
$transition-duration-slow-2: 1000ms !default;

// Transition timing functions
$transition-timing-function: ease-in-out !default;

// Transition delay
$transition-delay: 0ms !default;
