@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  align-items: center;
}

.container button[data-direction="left"] {
  left: 0;
  margin-inline: 0;
  display: none;
  font-size: $font-size-md;

  @include md-min {
      display: block;
  }
}

.container button[data-direction="right"] {
  right: 0;
  display: none;
  font-size: $font-size-md;

  @include md-min {
    display: block;
  }
}

:is(body) {
  .imageContainer {
    width: min(400px, 100%);
    margin-inline: auto;
  }

  .pagination {
    display: flex;
    margin-top: $space-sm;

    @include md-min {
      display: none;
    }
  }

  .circle {
    width: 8px;
    height: 8px;
    outline: 2px solid $carousel-navigation;
    outline-offset: 1px;
    background-color: $color-light;
    margin: $space-xxs;
  }

  .activeCircle {
    background-color: $carousel-navigation;
  }
}
