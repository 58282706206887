@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $additional-content-padding: $space-xs $space-lg $space-lg
);

.productDetailsPanel {
  // :where selector has 0 specificity, so it can be overridden by any other selector
  :where(.carouselContainer) {
    padding: 0;

    @include sm-max {
      border-bottom: 1px solid $color-border;
    }

    @include md-min {
      display: block;
      width: 50%;
      margin: 0;
      padding: 0;
    }

    @include lg-min {
      padding: 0;
      margin: 0;
      width: 60%;
    }
  }

  :where(.variationPanelContainer) {
    padding: 0;

    @include md-min {
      margin-left: 0;
      padding: 0;
      width: 50%;
    }

    @include lg-min {
      width: 40%;
    }
  }
}

.addToCartButton {
  padding: 0 $space-lg $space-lg;
  width: 100%;

  // Match the max right edge of the reviews section
  @include xl-min {
    padding: 0 calc(2.5vw + $space-xl) $space-lg $space-lg;
    max-width: $max-product-info-width;
  }
}

.additionalContentContainer {
  border-top: 1px solid $color-border;
}

.additionalContent {
  // Match the max right edge of the reviews section
  @include xl-min {
    padding: $space-xs calc(2.5vw + $space-xl) $space-lg $space-lg;
    max-width: $max-product-info-width;
  }
}

.quickview {
  &.productDetailsPanel:only-child {
    margin-bottom: 0;
  }

  :where(.addToCartButton) {
    padding-bottom: $space-sm;
  }

  :where(.carouselContainer) {
    margin-right: 0;
  }

  :where(.variationPanelContainer) {
    margin: 0;
  }
}
