@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;

  @include md-max {
    max-width: 900px;
  }
}

:is(body) {
  .thumbnails {
    display: flex; // Flex direction is set via the Slider component.
  }

  .navigationWrapper {
    height: 100%;

    @media (min-width: 1024px) {
      width: calc(100% - (2 * $space-xl));
    }
  }

  .navigation {
    @include square;
    @include button-transition;
    border-radius: 50%;
    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    background: $carousel-navigation-background;

    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
    }

    svg {
      width: 70px;
      transform: scale(0.7);
    }
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include lg-min {
      margin: auto;
      width: 70%;
    }
  }

  .pagination {
    margin-bottom: $space-sm;
  }

  .circle {
    width: 8px;
    height: 8px;
    outline: 2px solid $carousel-navigation;
    outline-offset: 1px;
    background-color: $color-light;
    margin: $space-xxs;
  }

  .activeCircle {
    background-color: $carousel-navigation;
  }
}