@use "@/styles/variables" as *;

.addToCartNotification {
  display: flex;
  flex-direction: column;
  font: 15px/18px 500 $body-font;
  width: 100%;
}

.notificationContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.notificationText {
  font: 15px/18px 500 $body-font;
  width: 75%;
}

.notificationLinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notificationLink {
  text-decoration: none;
}

.notificationButton {
  width: 100%;
}

.addToCartButton {
  &.exchange {
    background-color: $color-exchange-ui-bg;
    border-color: $color-exchange-ui-bg;
    color: $color-white;
  }
}

.statusMessage {
  margin: $space-xs 0;
  color: $color-dark;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  line-height: 1;

  // Keep a minimum height to prevent layout shift when this div is empty
  // (e.g. when the product is in stock and there's no status message to
  // display).
  min-height: $font-size-base;

  // Start invisible, and fade in with transition
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;

    // Only animate the opacity when the status message becomes visible.
    // This produces a fade-in effect, but not a fade-out effect when the
    // status message is hidden. This is intentional, since React will update
    // the status message text immediately when it changes, but we don't want
    // to show the "non-visible" status message at all. Therefore, we must
    // hide the status message immediately (i.e. without transition).
    transition-property: opacity;
    transition-duration: 150ms;
  }
}

.warning,
.quantityErrorText {
  color: $color-danger-600;
}

.spinner {
  width: $font-size-base;
  height: $font-size-base;

  color: $color-white;
}
