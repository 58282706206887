@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.addToCartButton {
  width: 100%;
  transition: all 0.15s ease-out;
}

.statusMessage {
  font-size: $font-size-xs;
  font-weight: $font-weight-base;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-small-text;
}

.quantityErrorText {
  letter-spacing: $letter-spacing-small-text;
}
