@use "@/styles/variables" as *;

////
/// Defines general-use utility functions and mixins.
/// @group utils/general
////

/// Hides the outline while the element is focused.
/// @outputs a selector combined with the current selector which
/// will match when the element is focused and sets the `outline`
/// property to `none`.
/// @example scss - Hiding input focus ring.
///   input {
///     @include hide-focus;
///   }
@mixin hide-focus {
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

/// Makes an element square at least in terms of aspect ratio.
/// The element doesn't actually have to be a literal square.
/// Must not set both arguments to a number.
/// @param {heightValue} $height The height value of the square.
/// @param {widthValue} $width The width value of the square.
/// @example scss - Add 50px square.
///   input {
///     @include square($width: 50px);
///   }
@mixin square($height: auto, $width: auto) {
  @if type-of($height) == (number) and type-of($width) == (number) {
    @error "Height and width must not be both set to a number.";
  }
  aspect-ratio: 1 / 1;
  width: $width;
  height: $height;
  overflow: hidden;
}

/// Makes an element circle using the square mixin.
/// @param {heightValue} $height The height value of the circle.
/// @param {widthValue} $width The width value of the circle.
/// @example scss - Add 50px circle.
///   input {
///     @include circle($width: 50px);
///   }

@mixin circle($height: auto, $width: auto) {
  @if type-of($height) == (number) and type-of($width) == (number) {
    @error "Height and width must not be both set to a number.";
  }

  @include square($width: $width, $height: $height);

  border-radius: 50%;
}

/// Makes an element invisible and unclickable.
/// But, when the `.visible` class is added to it, it will
/// appear with a slide transition.
/// @param {up | down | left | right} $direction The height value of the square.
/// @param {length} $slide-distance The width value of the square.
/// @example scss - Make a tooltip slide in when the `.visible` class is applied.
///   .tooltip {
///     @include slide-in(down);
///   }
@mixin slide-in($direction: up, $slide-distance: 15px) {
  // This is the default "hidden" state:
  opacity: 0; // Completely transparent...
  pointer-events: none; // ...and unclickable

  // Default translation will depend on direction.
  @if $direction == up {
    translate: 0 $slide-distance; // +Y
  } @else if $direction == down {
    translate: 0 (-1 * $slide-distance); // -Y
  } @else if $direction == left {
    translate: $slide-distance 0; // +X
  } @else if $direction == right {
    translate: (-1 * $slide-distance) 0; // -X
  } @else {
    @error "Unknown direction #{$direction}.";
  }

  // Switch opacity gracefully
  transition: opacity 150ms, translate 150ms;

  &.visible {
    // This is the "visible" state:
    opacity: 1; // Completely opaque...
    translate: 0 0; // ...no translation...
    pointer-events: all; // ...and clickable.
  }
}

@mixin navigation-button {
    @include square;

    border-radius: 50%;
    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    transition: border 1s, color 1s;
    background: $carousel-navigation-background;

    &:hover {
      border: 1px solid $color-black;
      color: $color-black;
    }

    svg {
      transform: scale(0.7);
    }
}

@mixin pagination-button {
    background-color: rgb(255 255 255 / 50%);
    border: 1px solid $color-gray-2;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    margin: 5px;
    transition: 300ms;
}

@mixin pagination-button-active {
  background-color: $color-gray-2;
  border-color: $color-white;
}

@mixin wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: $wrapper-max-width;
}

@mixin reset-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
