@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.icon {
  margin-right: $space-xs;
  width: 15px;

  svg {
    width: 100%;
  }
}

.content {
  max-width: $wrapper-max-width;
}

.button {
  max-width: $wrapper-max-width;
}

.container {
  .button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cardContainer {
  .button {
    padding: $space-sm $space-xs;
    background-color: $color-checkout-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $heading-font;
  }
}

.collection {
  display: contents;

  // At small screen sizes, the collection should allow the button to appear at end of the collection.
  .button {
    width: 100%;
    display: contents;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    @include circle(50px);
    text-align: center;
    display: flex;
    order: 1;
    align-items: center;
    background-color: $color-white;
    color: $color-primary;
    justify-content: center;
  }

  @include md-min {
    .button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.reverseContainer {
  .button {
    display: flex;
    align-items: center;
    margin-bottom: $space-xs;
  }

  .icon {
    order: -1;
  }
}

.open {
  height: auto;
  opacity: 1;
}

.closed {
  position: fixed;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
